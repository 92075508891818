const BASE_URL = "https://parallelum.com.br/fipe/api/v1";


export const fetchBrands = async () => {
  const url = `${BASE_URL}/caminhoes/marcas`;
  const response = await fetch(url);
  return await response.json();
};

export const fetchModelsForBrand = async (brand) => {
  const url = `${BASE_URL}/caminhoes/marcas/${brand}/modelos`;
  const response = await fetch(url);
  return await response.json();
};

export const fetchYears = async (brand, model) => {
  const url = `${BASE_URL}/caminhoes/marcas/${brand}/modelos/${model}/anos`;
  const response = await fetch(url);
  return await response.json();
};


