import { useState } from "react";
import { IconContext } from "react-icons";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useLocation } from "react-router-dom";

const Valor = () => {
  const [clicked, setClicked] = useState(false);
  const location = useLocation();
  const state = location.state;
  const valor = parseFloat(state.formValues.Valor.replace(/[^\d,]/g, ""));
  let adesao = 0.0;
  const open = true;

  const toogle = (open) => {
    if (clicked === open) {
      return setClicked(null);
    }
    setClicked(open);
  };

  if (valor <= 70000) {
    adesao = 1100;
  } else if (valor <= 110000) {
    adesao = 1436;
  } else if (valor <= 130000) {
    adesao = 1676;
  } else {
    adesao = 1916;
  }

  return (
    <IconContext.Provider value={{ color: "white", size: "25px" }}>
      <div className="fixed inset-x-0 bottom-0 lg:text-lg">
        <div className=" bg-red-600 ">
          <div
            className="text-sm lg:text-2xl flex text-center p-2 bg-red-700 text-slate-50"
            onClick={() => toogle(open)}
          >
            <span className="my-auto ">
              {clicked === open ? <FiChevronUp /> : <FiChevronDown />}
            </span>
            {valor === 0 || valor > 500000 ? (
              <h1 className="">
                Em breve um consultor entrará em contato para enviar uma cotação
                detalhada.
              </h1>
            ) : (
              <div className="w-full">
                <h1>
                  {" "}
                  De:{" "}
                  <span className="lg:text-xl line-through decoration-black">
                    R$ {(state.mensalidade / 100 + 200).toFixed(2)}
                  </span>{" "}
                </h1>
                <h1 className="text-lg lg:text-2xl font-bold">
                  {" "}
                  Por: R$ {(state.mensalidade / 100).toFixed(2)}
                </h1>
              </div>
            )}
          </div>
          {clicked === open ? (
            <div className="flex justify-between px-8 py-2 bg-gray-600 text-slate-50">
              <ul className="list-disc">
                <li>
                  O valor da mensalidade será cobrada mês a mês por boleto
                  bancário.
                </li>
                <li>
                  A cobertura será ativa em até 24h (após o cadastro ser
                  aprovado).
                </li>
                <li>
                  O valor da sua adesão será R$ 2.397,00 cobrado uma
                  única vez. (Adesão + Mensalidade).
                </li>
                <li>Franquia 3%</li>
                <li>
                  Um consultor entrará em contato para assinatura dos termos de
                  adesão e agendamento da vistoria.
                </li>
                <li>Cobertura de agregados a contratar</li>
              </ul>
            </div>
          ) : null}
        </div>
        </div>
    </IconContext.Provider>
  );
};

export default Valor;
