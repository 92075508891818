import { useState, useEffect } from "react";
import { fetchModelsForBrand } from "./../../helpers/fipe";

const DropdownModel = ({ brand, onChange = () => {} }) => {
  const [models, setModels] = useState([]);

  useEffect(() => {
    if(brand){
    fetchModelsForBrand(brand).then((models) => {
      setModels(models.modelos);
    }).catch(console.error);;}
  }, [brand]);
  
  return (
    <select
      id="model"
      name="model"
      onChange={onChange}
      className="my-1 p-2 bg-slate-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
    >
      <option value="">Selecione o modelo</option>
      {models.map((model) => {
        const { nome, codigo } = model;
        return (
          <option key={codigo} value={codigo}>
            {nome}
            
          </option>
        );
      })}
    </select>
  );
};

export default DropdownModel;
