import axios from "axios";

const sendEmail = (formValues, truck, mensalidade) => {
  if (formValues.email === "Sem email") {
    formValues.email = "sem@email.com";
  }
  if (formValues.Marca === undefined) {
    formValues.Marca = "Sem marca";
  }
  if (formValues.Modelo === undefined) {
    formValues.Modelo = "Sem modelo";
  }
  if (formValues.AnoModelo === undefined) {
    formValues.AnoModelo = "Sem ano";
  }
  if (formValues.AnoModelo === 32000) {
    formValues.AnoModelo = "Zero";
  }
  if (formValues.Valor === undefined) {
    formValues.Valor = "Sem valor";
  }
  if (truck === undefined) {
    truck = "Sem resposta";
  }

  const options = {
    method: "post",
    url: "https://eficazrepresentacao.com.br/api/cotacaoemail/index.php",
    headers: { "content-type": "application/json" },
    data: {
      name: formValues.name,
      email: formValues.email,
      phone: formValues.phone,
      Marca: formValues.Marca,
      Modelo: formValues.Modelo,
      AnoModelo: formValues.AnoModelo,
      Valor: formValues.Valor,
      truck: truck,
      mensalidade: mensalidade / 100,
      cotacao: "Cotacao2", // Alterar cotação --- Cotacao e sendMoskit
    },
  };
  axios
    .request(options)
    .then(function (response) {
      // console.log(response);
    })
    .catch(function (error) {
      // console.error(error);
    });
};

export default sendEmail;
