

const DropdownBrand = ({ onChange = () => {} }) => {


  const marcas = [
    {
      "nome": "AGRALE",
      "codigo": "102"
    },
    {
      "nome": "BEPOBUS",
      "codigo": "206"
    },
    {
      "nome": "CHEVROLET",
      "codigo": "103"
    },
    {
      "nome": "CICCOBUS",
      "codigo": "121"
    },
    {
      "nome": "DAF",
      "codigo": "197"
    },
    {
      "nome": "EFFA-JMC",
      "codigo": "179"
    },
    {
      "nome": "FIAT",
      "codigo": "104"
    },
    {
      "nome": "FORD",
      "codigo": "105"
    },
    {
      "nome": "FOTON",
      "codigo": "191"
    },
    {
      "nome": "GMC",
      "codigo": "106"
    },
    {
      "nome": "HYUNDAI",
      "codigo": "181"
    },
    {
      "nome": "IVECO",
      "codigo": "122"
    },
    {
      "nome": "JAC",
      "codigo": "188"
    },
    {
      "nome": "MAN",
      "codigo": "184"
    },
    {
      "nome": "MARCOPOLO",
      "codigo": "108"
    },
    {
      "nome": "MASCARELLO",
      "codigo": "196"
    },
    {
      "nome": "MAXIBUS",
      "codigo": "194"
    },
    {
      "nome": "MERCEDES-BENZ",
      "codigo": "109"
    },
    {
      "nome": "NAVISTAR",
      "codigo": "110"
    },
    {
      "nome": "NEOBUS",
      "codigo": "111"
    },
    {
      "nome": "PUMA-ALFA",
      "codigo": "112"
    },
    {
      "nome": "SAAB-SCANIA",
      "codigo": "113"
    },
    {
      "nome": "SCANIA",
      "codigo": "114"
    },
    {
      "nome": "SHACMAN",
      "codigo": "193"
    },
    {
      "nome": "SINOTRUK",
      "codigo": "166"
    },
    {
      "nome": "VOLKSWAGEN",
      "codigo": "115"
    },
    {
      "nome": "VOLVO",
      "codigo": "116"
    },
    {
      "nome": "WALKBUS",
      "codigo": "144"
    }
  ]


  return (
    <select
      id="brand"
      name="brand"
      onChange={onChange}
      className="my-1 p-2 bg-slate-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
    >
      <option value="">Selecione a marca</option>
      {marcas.map((brand) => {
        const { nome, codigo } = brand;
        return (
          <option key={codigo} value={codigo}>
            {nome}
          </option>
        );
      })}
    </select>
  );
};

export default DropdownBrand;
