import axios from "axios";

export function sendContato(formValues, truck, mensalidade, motivo) {
  var dayjs = require("dayjs");
  const date = dayjs().toISOString();

  if (formValues.email === "undefined" || formValues.email === "") {
    formValues.email = "Sem e-mail";
  }
  if (formValues.Marca === undefined) {
    formValues.Marca = "Sem marca";
  }
  if (formValues.Modelo === undefined) {
    formValues.Modelo = "Sem modelo";
  }
  if (formValues.AnoModelo === undefined) {
    formValues.AnoModelo = "Sem ano";
  }
  if (formValues.AnoModelo === 32000) {
    formValues.AnoModelo = "Zero";
  }
  if (formValues.Valor === undefined) {
    formValues.Valor = "Sem valor";
  }
  if (truck === undefined) {
    truck = "Sem resposta";
  }
  if (motivo === undefined) {
    motivo = "Sem resposta";
  }

  const cotacao = ` Marca:${formValues.Marca}, Modelo${formValues.Modelo}, Ano:${formValues.AnoModelo}, 
  Valor:${formValues.Valor},Mensalidade:${mensalidade}, ${truck}, ${motivo}`

  const options = {
    method: "POST",
    url: "https://api.moskitcrm.com/v2/contacts",
    headers: {
      apikey: "0bd11cb2-42b6-4e97-b132-f2b6c8c3f312",
      "Content-Type": "application/json",
      "X-Moskit-Origin": "WEB",
    },
    data: {
        "id": 0,
        "createdBy": {
          "id": 59727
        },
        "responsible": {
          "id": 59727
        },
        "name": formValues.name,
      "primaryEmail": {
          "id": 0
        },
        "primaryPhone": {
          "id": 0
        },
        "phones": [
          {
            "id": 0,
            "number": formValues.phone
           
            
          }
        ],
        "emails": [
          {
            "id": 0,
            "address": formValues.email
           
          }
        ],
          "entityCustomFields": [
          {
            "id": "CF_8P5q4aS2CAG38MRJ",
            "numericValue": 0,
            "textValue": cotacao,
            "options": [
              0
            ]
          }
        ]
    },
  };

  axios
    .request(options)
    .then(function (response) {
    })
    .catch(function (error) {
    });
}

export default sendContato;