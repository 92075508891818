import { useState, useEffect } from "react";
import { fetchYears } from "./../../helpers/fipe";

const DropdownYear = ({ brand, model, onChange = () => {} }) => {
  const [years, setYears] = useState([]);
  useEffect(() => {
    if(brand&&model){
    fetchYears(brand, model).then((years) => {
      setYears(years);
    }).catch(console.error);}
  }, [brand, model]);

  return (
    <select
      name="year"
      id="year"
      onChange={onChange}
      className="my-1 p-2 bg-slate-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-00 rounded-md"
    >
      <option value="">Selecione o ano</option>
      {years.map((year) => {
        const { nome, codigo } = year;
        return (
          <option key={codigo} value={codigo}>
            {nome}
          </option>
        );
      })}
    </select>
  );
};

export default DropdownYear;
