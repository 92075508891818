import SSL from './../../assets/SSL.svg';
import Acco from './Acco';


export default function FooterCotacao() {



  return (
    <div className="flex-col justify-end bg-slate-100  inset-x-0 bottom-0">
      <img src={SSL} alt="SSL Certificate" className="mx-auto p-4 max-w-3" />
      <div className="text-center p-2">
        <p className="font-bold">&copy; 2023 Eficaz Representação</p>
        <p className="font-bold">Todos os direitos reservados</p>
        <p className="m-2 md:mx-32 text-sm">A Eficaz Representação está no mercado desde 2008 providenciando apoio aos nossos clientes no momento em que é mais preciso.</p>
      </div>
      <Acco/>
    </div>
  );
}