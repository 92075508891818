import React, { useState, useEffect } from "react";
import Proteauto from "../assets/Proteauto.png";
import { SlEarphonesAlt } from "react-icons/sl";
import Acco from "../components/Footer/Acco";

import FAQ from "../components/FAQ";
import Banner from "../assets/truckbanner.png";


import Treze from "../assets/13anos.png";
import ReactPixel from "react-facebook-pixel";

export default function Obrigado() {

  useEffect(() => {
    ReactPixel.pageView(); // For tracking page view
    ReactPixel.trackCustom("Obrigado"); // For tracking custom'Cotacao2'); // For tracking custom . More info about custom : https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-
  }, []);

  return (
    <div className=" bg-slate-200">
      <div className="w-full p-2 bg-gray-700">
        <img
          src={Proteauto}
          alt="Logo da Proteauto"
          className="w-2/3 mx-auto"
        />
      </div>


      <div className="flex bg-red-700 rounded-md shadow-md p-2 m-4 md:mx-40 lg:mx-80 text-white text-center ">
        <div className="my-auto p-2">
          <SlEarphonesAlt className="text-3xl" />
        </div>
        <div className="text-sm lg:text-xl">
          <p>
          Em breve, uma consultora da nossa equipe entrará em contato com você para passar mais informações a respeito da proteção.
          </p>
        </div>

        {/* EM BREVE, ALGUMA DE NOSSAS CONSULTORAS ENTRARÁ EM CONTATO COM VOCÊ PARA
        PASSAR MAIS INFORMAÇÕES A RESPEITO DA PROTEÇÃO. */}
      </div>
      <div className="">
        <img src={Banner} alt="Banner" className="object-cover h-18" />
      </div>

      <div className="pt-4 m-4 md:mx-40 lg:mx-80">
        <h1 className="text-lg font-bold leading-snug	pb-2	">
          POR QUE SER UM ASSOCIADO PROTEAUTO?
        </h1>
        <div>
          A Proteauto está desde 2009 no mercado. Uma empresa que vem crescendo
          a cada ano que passa, hoje já somos <a className="underline decoration-red-500">mais de 20.000 associados</a>  em todo
          o Brasil, em média 600 novos associados aderindo nossa proteção por
          mês. <a className="underline decoration-red-500">A Proteauto entrega tudo aquilo que promete</a>, por isso tem
          crescido diariamente.
        </div>
      </div>
      <div className="w-40 md:w-60 pb-4 mx-auto">
        <img src={Treze} alt="13 anos protegendo o caminhoneiro" />
      </div>
      <div className="bg-red-700 p-4 text-white text-center">
        VEJA ALGUNS DEPOIMENTOS DE NOSSOS ASSOCIADOS
      </div>
      <div className="m-4 md:mx-40 lg:mx-80">
        <div>
          <iframe
            className="w-full rounded-md md:h-56"
            src="https://www.youtube.com/embed/_JBpKVcztBQ"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </div>
      </div>
      <div className="m-4 md:mx-40 lg:mx-80">
        <FAQ />
      </div>

      <div className="my-auto py-2 m-4 md:mx-40 lg:mx-80">
        <a
          className="inline-flex justify-center p-8 py-2 px-10 md:px-20 border border-transparent shadow-sm text-sm lg:text-xl font-medium text-center rounded-full text-white bg-green-700 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 w-full"
          href="https://wa.me/5544991574068?text=Ol%C3%A1%2C+tenho+interesse+em+saber+um+pouco+mais%21"
        >
          Tenho interesse e quero saber mais!
        </a>
      </div>
      <div className="flex-col justify-end bg-slate-100  inset-x-0 bottom-0">
        <div className="text-sm text-center p-2">
          <p className="font-bold">&copy; 2024 Eficaz Representação</p>
          <p className="font-bold">Todos os direitos reservados</p>
          <p className="m-2 text-xs md:mx-40 lg:mx-80">
            A Eficaz Representação está no mercado desde 2008 providenciando
            apoio aos nossos clientes no momento em que é mais preciso.
          </p>
        </div>
        <Acco />
      </div>
    </div>
  );
}
