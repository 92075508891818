import Cotacao from "./pages/Cotacao";
import Proposta from "./pages/Proposta";
import Obrigado from "./pages/Obrigado";
import { Routes, Route } from "react-router-dom";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Avatar from "./assets/avatar.jpg"


import ReactPixel from 'react-facebook-pixel';
ReactPixel.init('920544564994886');



function App() {
  return (
    <div className="w-screen bg-gray-500">
      <Routes className="flex ">
        <Route path="/" element={<Cotacao />} />
        <Route path="/proposta" element={<Proposta />} />
        <Route path="/obrigado" element={<Obrigado />} />
      </Routes>
      <FloatingWhatsApp  
          phoneNumber="5544991574068" 
          avatar={Avatar}
          accountName="Proteauto" 
          statusMessage="Responderemos em breve"
          chatMessage="Olá 👋, em que podemos ajudar? "
          placeholder="Digite sua mensagem..."
          allowClickAway="true"
          />
    </div>
  );
}

export default App;
